import React from 'react';
import { motion, useAnimation } from 'framer-motion';
import Plx from 'react-plx';
import { Lottie } from '@crello/react-lottie';
import { keyframes } from '@emotion/core';

import SEO from 'src/components/SEO';
import { ProjectCarousel } from 'src/components';
import styled from 'src/components/styled';
import About from 'src/components/About';
import Typography from 'src/components/Typography';
import DisplayIntro from 'src/components/DisplayIntro';

const fadeIn = keyframes`
    0% { opacity:0; }
    66% { opacity:0; }
    100% { opacity:1; }
`;

const textData = [
  {
    start: 'self',
    duration: '100vh',
    easing: 'easeInOut',
    properties: [
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      },
    ],
  },
];

const aboutSectionData = [
  {
    start: 'self',
    duration: '25vh',
    startOffset: '50vh',
    properties: [
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      },
    ],
  },
];

const HomeBackground = styled(motion.div)`
  position: relative;
  min-height: 100vh;
  display: flex;
`;

const HomeSection = styled(motion.section)`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 900px;
`;

const HomeTopBackground = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
  bottom: 50%;
`;

const SectionTitle = styled(Typography)`
  font-size: 12vw;
`;

const AboutSection = styled(motion.section)``;

const AnimationContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: -300px;
  margin: 0 auto;
  animation: 3s ease-in-out 0s normal forwards 1 ${fadeIn};
`;

const IndexPage = (props) => {
  const homeTopBackgroundAnimation = useAnimation();

  return (
    <>
      <SEO title="Home" />
      <div
        style={{
          margin: '0 auto',
          maxWidth: 1200,
          minHeight: '100vh',
          padding: '96px 32px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <DisplayIntro />

        <AnimationContainer>
          <Lottie
            config={{
              path: 'https://assets8.lottiefiles.com/packages/lf20_f56jP4.json',
              loop: true,
            }}
            height="800px"
          />
        </AnimationContainer>
      </div>
      <Plx className="StickyText" parallaxData={aboutSectionData} style={{ opacity: 0 }}>
        <motion.div
          style={{
            color: 'rgb(255,255,255)',
            padding: '0 32px',
          }}
          initial={{ backgroundColor: 'rgb(50,50,50)' }}
          animate={homeTopBackgroundAnimation}
        >
          <div style={{ height: '50vh' }} />
          <div style={{ height: '50vh' }}>
            <div
              style={{
                margin: '0 auto',
                maxWidth: 1200,
                position: 'sticky',
                top: 30,
              }}
            >
              <Plx className="StickyText" parallaxData={textData} style={{ opacity: 0 }}>
                <SectionTitle as="h1">Projects</SectionTitle>
              </Plx>
            </div>
          </div>
        </motion.div>
      </Plx>

      <HomeBackground
        style={{
          backgroundColor: '#fff',
        }}
        id="projects"
      >
        <HomeSection>
          <HomeTopBackground
            initial={{ backgroundColor: 'rgb(50,50,50)' }}
            animate={homeTopBackgroundAnimation}
          />
          <ProjectCarousel homeTopBackgroundAnimation={homeTopBackgroundAnimation} />
        </HomeSection>
      </HomeBackground>
      <div style={{ position: 'relative', zIndex: 1 }}>
        <Plx className="StickyText" parallaxData={aboutSectionData} style={{ opacity: 0 }}>
          <motion.div
            style={{
              backgroundColor: 'rgb(2, 6, 18)',
              color: '#fff',
              padding: '0 32px',
            }}
          >
            <div style={{ height: '50vh' }} />
            <div style={{ height: '50vh' }}>
              <div
                style={{
                  margin: '0 auto',
                  maxWidth: 1200,
                  position: 'sticky',
                  top: 30,
                }}
              >
                <Plx className="StickyText" parallaxData={textData} style={{ opacity: 0 }}>
                  <SectionTitle as="h1">About me</SectionTitle>
                </Plx>
              </div>
            </div>
          </motion.div>
        </Plx>

        <AboutSection>
          <About />
        </AboutSection>
      </div>
    </>
  );
};

export default IndexPage;
