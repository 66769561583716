import React, { useEffect, useContext, useRef } from 'react';
import { motion, useSpring, useAnimation } from 'framer-motion';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

import styled, { mq } from 'src/components/styled';
import Typography from 'src/components/Typography';
import { CursorContext } from 'src/layouts/RootLayout';

const ExtLinkText = styled(Typography)`
  display: inline-block;
  margin: 0;
`;

const ExternalLink = styled(OutboundLink)`
  color: inherit;
  display: inline-block;
  margin: 0 0 0 -16px;
  padding: 8px 16px;
`;

const Container = styled.span`
  position: relative;
  display: inline-block;
`;
const ButtonHoverHandler = styled(motion.span)`
  background-color: rgba(255, 255, 255, 0);
  position: relative;
  z-index: 2;
  display: inline-block;
  color: #fff;
`;
const ButtonHoverBackground = styled(motion.span)`
  background-color: rgba(200, 200, 200, 0.9);
  height: 100%;
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  border-radius: 8px;
  opacity: 0;
  display: inline-block;
`;

export default function ExtLink({ href, label }: { href: string; label: string }) {
  const buttonRef = useRef();
  const isHover = useRef(false);
  const { x, y, animation } = useContext(CursorContext);

  const iconScale = useSpring(1, { stiffness: 100, damping: 10 });

  const bgX = useSpring(0, { stiffness: 300, damping: 30, mass: 0.8 });
  const bgY = useSpring(0, { stiffness: 300, damping: 30, mass: 0.8 });
  const bgAnimation = useAnimation();

  const hoverHandlerAnimation = useAnimation();

  useEffect(() => {
    x.onChange((newX) => {
      if (isHover.current && buttonRef.current) {
        bgX.set(
          (newX -
            (buttonRef.current.getBoundingClientRect().left +
              buttonRef.current.getBoundingClientRect().right) /
              2 -
            18 / 2) *
            0.2,
        );
      }
    });
    y.onChange((newY) => {
      if (isHover.current && buttonRef.current) {
        bgY.set((newY - buttonRef.current.getBoundingClientRect().top) * 0.2);
      }
    });
  }, [x, bgX, y, bgY]);

  const handleHoverEnd = async () => {
    isHover.current = false;
    iconScale.set(1);
    animation.start({ opacity: 1 }, { duration: 0.1 });
    hoverHandlerAnimation.start({ color: '#fff' });
    await bgAnimation.start({ opacity: 0, scale: 0.8 }, { duration: 0.2 });
    bgY.set(0);
    bgX.set(0);
  };

  return (
    <Container>
      <ButtonHoverHandler
        onHoverStart={() => {
          isHover.current = true;
          iconScale.set(1.1);
          hoverHandlerAnimation.start({ color: 'rgb(47, 47, 47)' }, { duration: 0.1 });
          bgAnimation.start({ opacity: 1, scale: 1 }, { duration: 0.4 });
          animation.start({ opacity: 0 }, { duration: 0.1 });
        }}
        onHoverEnd={handleHoverEnd}
        onMouseDown={() => {
          bgAnimation.start({ scale: 0.85 });
        }}
        onMouseUp={() => {
          bgAnimation.start({ scale: 1 });
        }}
        ref={buttonRef}
        animate={hoverHandlerAnimation}
      >
        <ExtLinkText>
          <ExternalLink
            href={href}
            {...(!href.startsWith('mailto') && { target: '_blank', rel: 'noopener' })}
          >
            {label}
          </ExternalLink>
        </ExtLinkText>
      </ButtonHoverHandler>
      <ButtonHoverBackground
        style={{
          x: bgX,
          y: bgY,
          scale: 0.85,
          originX: 0.5,
          originY: 0.5,
        }}
        animate={bgAnimation}
      />
    </Container>
  );
}
