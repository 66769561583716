import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import VisibilitySensor from 'react-visibility-sensor';
import { motion, useAnimation } from 'framer-motion';

import styled, { mq } from 'src/components/styled';
import Typography from 'src/components/Typography';
import ExtLink from './ExtLink';

const Container = styled.div`
  min-height: 100vh;
  background-color: rgb(2, 6, 18);
  color: #fff;
  display: flex;
  justify-content: center;
`;

const ContentContainer = styled.div`
  width: 1200px;
  padding: 96px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

const InnerContentContainer = styled.div`
  ${mq({ display: ['block', 'block', 'block', 'flex'] })};
  width: 100%;
  justify-content: space-between;
`;

const Header = styled.div`
  display: flex;
  ${mq({ 'flex-direction': ['column', 'row'] })};
  ${mq({ 'align-items': ['', 'center'] })};
  ${mq({ 'margin-bottom': [32, 40] })};
`;

const TextContainer = styled(motion.div)`
  ${mq({ 'max-width': ['', '', '600px'] })};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled(Typography)`
  ${mq({ 'font-size': [32, 32, 48] })};
  font-weight: 100;
  ${mq({ 'max-width': ['', '235px', '350px'] })};
  margin: 0;
  ${mq({ 'margin-left': ['', '40px'] })};
`;

const AnimatedTitleWords = styled(motion.div)`
  display: inline-block;
`;

const ImageContainer = styled(motion.div)`
  ${mq({ 'max-width': ['150px', '150px', '200px'] })};
  width: 100%;
  ${mq({ 'margin-bottom': [32, 0] })};
`;

const Paragraph = styled(Typography)`
  font-size: 16;
  font-weight: 300;
  ${mq({ 'margin-bottom': ['24px', '32px'] })};
`;

const ContactLinks = styled(motion.div)`
  ${mq({
    'margin-top': ['40px', '40px', '40px', 0],
    'align-self': ['', '', 'flex-end', 'flex-end'],
    'text-align': ['', '', 'right', 'right'],
  })};
`;

const transition = { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] };

const titleContainerVariants = {
  start: {},
  visible: { transition: { staggerChildren: 0.15 } },
  exit: {},
};

const titleWordsVariants = {
  start: (startSmall) => ({ opacity: 0, scale: startSmall ? 0.8 : 1 }),
  visible: { opacity: 1, scale: 1, transition },
  exit: {
    opacity: 0,
    scale: 1,
    transition: { ...transition },
  },
};

const rowContainerVariants = {
  start: {},
  visible: (reversed = false) => ({
    transition: { staggerChildren: 0.2, staggerDirection: reversed ? -1 : 1 },
  }),
  exit: {},
};

const rowVariants = {
  start: { opacity: 0 },
  visible: { opacity: 1, transition },
  exit: {
    opacity: 0,
    transition: { ...transition },
  },
};

export default function About() {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/kevin_red.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const imageAnimation = useAnimation();
  const titleAnimation = useAnimation();
  const paragraphsAnimation = useAnimation();
  const linksAnimation = useAnimation();

  return (
    <Container>
      <ContentContainer>
        <InnerContentContainer>
          <div>
            <VisibilitySensor
              onChange={async (v) => {
                if (v) {
                  imageAnimation.start('visible');
                  await titleAnimation.start('visible');
                  paragraphsAnimation.start('visible');
                  linksAnimation.start('visible');
                }
              }}
            >
              <Header>
                <ImageContainer initial="start" variants={rowVariants} animate={imageAnimation}>
                  <Img fluid={data.file.childImageSharp.fluid} alt="Headshot of Kevin Gelpes" />
                </ImageContainer>
                <motion.div
                  initial="start"
                  animate={titleAnimation}
                  variants={titleContainerVariants}
                >
                  <Title as="h2">
                    <AnimatedTitleWords initial="start" variants={titleWordsVariants}>
                      I'm
                    </AnimatedTitleWords>{' '}
                    <AnimatedTitleWords
                      initial="start"
                      variants={titleWordsVariants}
                      style={{ fontWeight: 500 }}
                      custom
                    >
                      Kevin
                    </AnimatedTitleWords>{' '}
                    <AnimatedTitleWords
                      initial="start"
                      variants={titleWordsVariants}
                      style={{ fontWeight: 500 }}
                      custom
                    >
                      Gelpes
                    </AnimatedTitleWords>{' '}
                    <AnimatedTitleWords initial="start" variants={titleWordsVariants}>
                      and I'm creating
                    </AnimatedTitleWords>{' '}
                    <AnimatedTitleWords
                      initial="start"
                      variants={titleWordsVariants}
                      style={{ fontWeight: 500 }}
                      custom
                    >
                      better software
                    </AnimatedTitleWords>
                    <AnimatedTitleWords initial="start" variants={titleWordsVariants}>
                      .
                    </AnimatedTitleWords>
                  </Title>
                </motion.div>
              </Header>
            </VisibilitySensor>
            <TextContainer
              variants={rowContainerVariants}
              animate={paragraphsAnimation}
              initial="start"
            >
              <motion.div variants={rowVariants} initial="start">
                <Paragraph>
                  As a Frontend Developer and Technical Leader, I have a strong background in the
                  development of web and mobile applications. I have over 8 years of experience with
                  JS/Frontend Development and 5+ years of experience with React and React Native.
                </Paragraph>
              </motion.div>
              <motion.div variants={rowVariants} initial="start">
                <Paragraph>
                  I have a passion for the web3 industry and have a keen eye for UI and UX design. I
                  thrive on building things and solving complex technical problems.
                </Paragraph>
              </motion.div>
              <motion.div variants={rowVariants} initial="start">
                <Paragraph>
                  I have worked for a variety of companies and industries, including Polymarket,
                  Momentranks, Apollo Brokers, and Effectus Software, where I have honed my skills
                  and gained valuable experience in the field. Prior to these roles, I worked as a
                  freelance web developer for various local clients.
                </Paragraph>
              </motion.div>
              <motion.div variants={rowVariants} initial="start">
                <Paragraph style={{ marginBottom: 0 }}>
                  I am dedicated to staying up-to-date with the latest technologies and industry
                  trends, and am always looking for new opportunities to challenge myself and grow
                  as a developer.
                </Paragraph>
              </motion.div>
            </TextContainer>
          </div>
          <ContactLinks variants={rowContainerVariants} animate={linksAnimation} initial="start">
            <motion.div variants={rowVariants} initial="start">
              <ExtLink href="https://www.linkedin.com/in/kgelpes/" label="LinkedIn" />
            </motion.div>
            <motion.div variants={rowVariants} initial="start">
              <ExtLink href="https://medium.com/@kgelpes" label="Medium" />
            </motion.div>
            <motion.div variants={rowVariants} initial="start">
              <ExtLink href="https://stackoverflow.com/u/8437254" label="Stack Overflow" />
            </motion.div>
            <motion.div variants={rowVariants} initial="start">
              <ExtLink href="mailto:kevin@gelpes.dev" label="kevin@gelpes.dev" />
            </motion.div>
          </ContactLinks>
        </InnerContentContainer>
      </ContentContainer>
    </Container>
  );
}
