import React from 'react';
import { motion } from 'framer-motion';
import styled, { mq } from 'src/components/styled';

import Typography from 'src/components/Typography';

const containerVariants = {
  before: {},
  after: { transition: { staggerChildren: 0.12 } },
};

const letterVariants = {
  before: {
    opacity: 0,
  },
  after: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};

const Name = styled(Typography)`
  font-weight: 800;
  margin: 0;
  ${mq({
    fontSize: ['12vw', '14vw', '14vw', '14vw', 182],
    lineHeight: [1.2, 1.1, 1, 1],
  })}
`;

const Subtitle = styled(Typography)`
  font-weight: 500;
  line-height: 1;

  color: #5b5b5b;
  ${mq({
    fontSize: ['5.99vw', '6.99vw', '6.99vw', '6.99vw', 91],
  })}
`;

const SubtitleContainer = styled(motion.div)`
  ${mq({
    'margin-left': ['1px', '2px', '3px'],
    'margin-top': ['-10px', '-16px', '-20px'],
  })}
`;

export default function DisplayIntro() {
  return (
    <>
      <motion.div
        style={{
          color: '#000',
          fontWeight: 'bold',
          lineHeight: 1.2,
        }}
        variants={containerVariants}
        initial="before"
        animate="after"
        exit="before"
      >
        {['K', 'e', 'v', 'i', 'n', ' '].map((letter, index) => (
          <motion.div
            key={`a-${index}`}
            style={{
              position: 'relative',
              display: 'inline-block',
            }}
            variants={letterVariants}
          >
            <Name as="h1">{letter === ' ' ? '\u00A0' : letter}</Name>
          </motion.div>
        ))}
        {['Gel', 'pes'].map((letter, index) => (
          <motion.div
            key={`a-${index}`}
            style={{
              position: 'relative',
              display: 'inline-block',
            }}
            variants={letterVariants}
          >
            <Name as="h1">{letter === ' ' ? '\u00A0' : letter}</Name>
          </motion.div>
        ))}
        <br />
        <SubtitleContainer>
          {['Front', 'end', ' ', 'De', 'velo', 'per'].map((letter, index) => (
            <motion.div
              key={`b-${index}`}
              variants={letterVariants}
              style={{
                position: 'relative',
                display: 'inline-block',
              }}
            >
              <Subtitle as="h2">{letter === ' ' ? '\u00A0' : letter}</Subtitle>
            </motion.div>
          ))}
        </SubtitleContainer>
      </motion.div>
    </>
  );
}
